.cookie-consent {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: #003c69;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  flex-wrap: wrap;
}

.cookie-consent p {
  color: #ffffff;
  margin-bottom: 0;
}

.cookie-consent a {
  color: #df7a00;
  margin-left: 10px;
  transition: color 200ms ease-in-out;
}

.cookie-consent a:hover {
  color: #ff9413;
}

.cookie-consent button {
  margin-left: 20px;
  margin-right: 20px;
  background-color: #df7a00;
  color: #ffffff;
  padding: 5px 15px;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
}

.cookie-consent button:hover {
  background-color: #ff9413;
}

