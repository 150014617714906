$cocemfeBlue: #003c69!default;
$cookieBg: $cocemfeBlue;
$cookieColor: #ffffff!default;
$cocemfeOrange: #df7a00!default;
$cookieLinkColor: $cocemfeOrange;

.cookie-consent {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: $cookieBg;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
    flex-wrap: wrap;
    p {
        color: $cookieColor;
        margin-bottom: 0;
    }
    a {
        color: $cookieLinkColor;
        margin-left: 10px;
        transition: color 200ms ease-in-out;
        &:hover {
            color: lighten($cookieLinkColor, 10%);
        }
    }
    button {
        margin-left: 20px;
        margin-right: 20px;
        background-color: $cookieLinkColor;
        color: $cookieColor;
        padding: 5px 15px;
        border-radius: 10px;
        outline: none;
        border: none;
        cursor: pointer;
        transition: background-color 200ms ease-in-out;
        &:hover {
            background-color: lighten($cookieLinkColor, 10%);
        }
    }
}
